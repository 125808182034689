import { defineStore } from "pinia";

export enum NotificationType {
	INFO = "informational",
	NEUTRAL = "neutral",
	SUCCESS = "success",
	WARNING = "warning",
	ATTENTION = "attention",
	ERROR = "error",
}

export enum NotificationPosition {
	TOP_LEFT = "top-left",
	TOP_CENTER = "center-top",
	TOP_RIGHT = "top-right",
	BOTTOM_LEFT = "bottom-left",
	BOTTOM_CENTER = "center-bottom",
	BOTTOM_RIGHT = "bottom-right",
	INLINE = "inline",
}

interface NotificationAction {
	text: string;
	type?: "primary" | "secondary";
	fn: () => void;
}

interface NotificationProps {
	title: string;
	message?: string;
	action?: NotificationAction;
	duration?: number;
	position?: NotificationPosition;
	closable?: boolean;
	orientation?: "horizontal-responsive" | "vertical-responsive" | "horizontal" | "vertical";
}

const defaults = {
	title: "",
	message: "",
	action: null,
	type: NotificationType.SUCCESS,
	position: NotificationPosition.TOP_CENTER,
	duration: 5000,
	closable: false,
	maxWidth: "550px",
	orientation: "horizontal-responsive",
};

export const useNotificationStore = defineStore("notifications", () => {
	const title = ref(defaults.title);
	const message = ref(defaults.message);
	const action = ref<NotificationAction | null>(defaults.action);
	const type = ref(defaults.type);
	const position = ref(defaults.position);
	const horizontalPosition = computed(() => position.value.split("-")[0]);
	const verticalPosition = computed(() => position.value.split("-")[1]);
	const closable = ref(defaults.closable);
	const duration = ref(defaults.duration);
	const showNotification = computed(() => {
		return !!title.value;
	});
	const maxWidth = ref(defaults.maxWidth);
	const orientation = ref(defaults.orientation);

	function success({
		title: t,
		message: msg,
		action: act,
		duration: dur,
		position: pos,
		closable: close,
		orientation: orient,
	}: NotificationProps) {
		type.value = NotificationType.SUCCESS;
		setNotification({
			title: t,
			message: msg,
			action: act,
			duration: dur,
			position: pos,
			closable: close,
			orientation: orient,
		});
	}

	function info({
		title: t,
		message: msg,
		action: act,
		duration: dur,
		position: pos,
		closable: close,
		orientation: orient,
	}: NotificationProps) {
		type.value = NotificationType.INFO;
		setNotification({
			title: t,
			message: msg,
			action: act,
			duration: dur,
			position: pos,
			closable: close,
			orientation: orient,
		});
	}

	function neutral({
		title: t,
		message: msg,
		action: act,
		duration: dur,
		position: pos,
		closable: close,
		orientation: orient,
	}: NotificationProps) {
		type.value = NotificationType.NEUTRAL;
		setNotification({
			title: t,
			message: msg,
			action: act,
			duration: dur,
			position: pos,
			closable: close,
			orientation: orient,
		});
	}

	function warning({
		title: t,
		message: msg,
		action: act,
		duration: dur,
		position: pos,
		closable: close,
		orientation: orient,
	}: NotificationProps) {
		type.value = NotificationType.WARNING;
		setNotification({
			title: t,
			message: msg,
			action: act,
			duration: dur,
			position: pos,
			closable: close,
			orientation: orient,
		});
	}

	function attention({
		title: t,
		message: msg,
		action: act,
		duration: dur,
		position: pos,
		closable: close,
		orientation: orient,
	}: NotificationProps) {
		type.value = NotificationType.ATTENTION;
		setNotification({
			title: t,
			message: msg,
			action: act,
			duration: dur,
			position: pos,
			closable: close,
			orientation: orient,
		});
	}

	function error({
		title: t,
		message: msg,
		action: act,
		duration: dur,
		position: pos,
		closable: close,
		orientation: orient,
	}: NotificationProps) {
		type.value = NotificationType.ERROR;
		setNotification({
			title: t,
			message: msg,
			action: act,
			duration: dur,
			position: pos,
			closable: close,
			orientation: orient,
		});
	}

	function setNotification({
		title: t,
		message: msg,
		action: act,
		duration: dur,
		position: pos,
		closable: close,
		orientation: orient,
	}: NotificationProps) {
		title.value = t;
		message.value = msg || "";
		closable.value = close || false;
		if (act) {
			action.value = {
				text: act.text,
				type: act.type,
				fn: () => {
					act.fn();
					reset(0);
				},
			};
		} else {
			action.value = null;
		}
		// If action is defined and no duration, we assume the notification should be closed only on action
		if (act?.fn && !dur) {
			duration.value = Infinity;
		} else if (dur && typeof dur === "number") {
			duration.value = dur;
		} else {
			duration.value = defaults.duration;
		}

		if (pos) {
			position.value = pos;
		} else {
			position.value = defaults.position;
		}

		if (duration.value && duration.value !== Infinity) {
			reset(duration.value);
		}
		if (orient) {
			orientation.value = orient;
		} else {
			orientation.value = defaults.orientation;
		}
	}

	function reset(time: number) {
		setTimeout(() => {
			title.value = defaults.title;
			message.value = defaults.message;
			action.value = defaults.action;
			duration.value = defaults.duration;
			type.value = defaults.type;
			position.value = defaults.position;
			maxWidth.value = defaults.maxWidth;
			orientation.value = defaults.orientation;
		}, time);
	}

	return {
		title,
		message,
		action,
		type,
		duration,
		position,
		showNotification,
		closable,
		horizontalPosition,
		verticalPosition,
		maxWidth,
		orientation,
		success,
		info,
		neutral,
		warning,
		attention,
		error,
		reset,
	};
});
